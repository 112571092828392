/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'theme/shared-report-table.scss';
@import 'theme/status-colors.scss';

.small-margin-left {
  margin-left: 3px;
}

.content-left {
  text-align: left;
}

.content-right {
  text-align: right;
}

.screen-reader-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  width: 1px;
}
.square-chip {
  border-radius: 4px;
}

.logo {
  width: 140px;
}

.small-chip {
  height: 22px;
  font-weight: normal;
  font-size: 12px;
  margin-left: 0;
  ion-icon {
    font-size: 16px;
  }
}

.text-weight-600 {
  font-weight: 600;
}

.driver-avatar {
  background-color: var(--ion-color-primary);
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
}

.centered {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

#typeform {
  iframe {
    height: 90vh;
    width: 100%;
  }
}

.error-text {
  padding-bottom: 5px;
}

@media only screen and (min-width: 100px) and (max-width: 555px) {
  #siqiframe {
    padding-top: var(--ion-safe-area-top, 0) !important;
  }
}

.training-modal {
  --width: 95vw;
  --height: 95vh;

  display: flex;
  justify-content: center;
  align-items: center;
  .training-container {
    iframe {
      width: 100%;
      height: 85vh;
    }
  }
}

.pdf-preview-modal {
  --width: 95vw;
  --height: 95vh;
}

.custom-loading-overlay {
  --background: transparent;
  .loading-wrapper.sc-ion-loading-md,
  .loading-wrapper.sc-ion-loading-ios {
    box-shadow: none;
  }
}

.small-modal {
  --max-height: 340px;
}

.big-modal {
  --width: 95vw;
  --height: 95vh;
}

.error-text-danger {
  color: var(--ion-color-danger);
  font-size: 12px;
}

.filter-modal {
  --width: 350px;
  --height: auto;
  --max-height: 80vh;

  &::part(content) {
    position: absolute;
    top: 67px;
    right: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  }
}

.interface-fleet-option {
  --ion-color-primary: var(--ion-color-secondary) !important;

  .alert-button {
    color: var(--ion-color-secondary) !important;
  }

  .alert-checkbox {
    border-color: var(--ion-color-secondary) !important;
  }
}
